import React from "react";
import "./Produk2.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLocationDot,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

import Fremontimages from "./Freemont";
import Thompsonimages from "./Thompson";
import Nismaraimages from "./Nismara";

const Produk2 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Nismarawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281311110717&text=Halo%20Uki,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Kota%20Harapan%20Indah%20(Nismara)%20https://kota-harapan-indah.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Fremontwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281311110717&text=Halo%20Uki,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Kota%20Harapan%20Indah%20(Fremont)%20https://kota-harapan-indah.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Thompsonwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281311110717&text=Halo%20Uki,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Kota%20Harapan%20Indah%20(Thompson)%20https://kota-harapan-indah.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Albasiawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281311110717&text=Halo%20Uki,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Kota%20Harapan%20Indah%20(Albasia)%20https://kota-harapan-indah.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="container">
      <div className="container-card3"></div>
      <div></div>
    </div>
  );
};

export default Produk2;
