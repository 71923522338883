import Hoshi1 from '../../../Media/KHI/Hoshi/Hoshi (1).webp'
import Hoshi2 from '../../../Media/KHI/Hoshi/Hoshi (2).webp'
import Hoshi3 from '../../../Media/KHI/Hoshi/Hoshi (3).webp'
import Hoshi4 from '../../../Media/KHI/Hoshi/Hoshi (4).webp'
import Hoshi5 from '../../../Media/KHI/Hoshi/Hoshi (5).webp'
import Hoshi6 from '../../../Media/KHI/Hoshi/Hoshi (6).webp'
import Hoshi7 from '../../../Media/KHI/Hoshi/Hoshi (7).webp'
import Hoshi8 from '../../../Media/KHI/Hoshi/Hoshi (8).webp'
import Hoshi9 from '../../../Media/KHI/Hoshi/Hoshi (9).webp'



const Hoshiimages =[
Hoshi1,Hoshi2,Hoshi3,Hoshi4,Hoshi5,Hoshi6,Hoshi7,Hoshi8,Hoshi9
]

export default Hoshiimages;